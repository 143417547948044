<div class="km-dialog-container">
  <mat-dialog-content class="dialog-text">
    <div class="header-container">
      <div *ngIf="!formData" class="tittleheader">{{ 'excessKm' | i18n }} ({{'calculating' | i18n}})
        <span>{{ 'excessKmsub' | i18n }}</span>
      </div>
      <div *ngIf="formData" class="tittleheader">{{ 'excessKm' | i18n }} ({{exceededKms}} KM)
        <span>{{ 'excessKmsub' | i18n }}</span>
      </div>

      <!--<button class="cancel" mat-button [mat-dialog-close]="true" (click)="onNoClick()">X</button>-->
      <img src="/assets/images/Grupo 6325.svg" (click)="onNoClick()">

    </div>
    <div class="text-dialog-container">
      <p class="selection-notice" [innerHTML]=" 'whatdouwtd'| i18n "></p>
    </div>
    <div class="km-pay">
      <div class="container-imagen">
        <div class="km-item km-orange">
          <p class="title" [innerHTML]=" 'additionalCost' | i18n "></p>
          <div class="horizontal-line-first"></div>
          <div class="euros">
            <p *ngIf="kmRate != null"> {{kmRate.price}}</p>
            <span>€/km</span>
          </div>
          <div class="horizontal-line-second"></div>
          <div class="taxes">
            <p>*{{'taxesIncluded' | i18n }}</p>
          </div>
          <form *ngIf="formData" id="formRedsys" action="{{formData.url}}" method="POST" #form>
            <input name="DS_SIGNATUREVERSION" value="{{formData.version}}" type="hidden">
            <input name="DS_MERCHANTPARAMETERS" value="{{formData.parameters}}" type="hidden">
            <input name="DS_SIGNATURE" value="{{formData.signature}}" type="hidden">
          </form>
          <div class="button-container">
            <div class="continue-button" [ngClass]="{ 'loading-btn': !formData }">
              <a *ngIf="!formData">{{'loading' | i18n}}</a>
              <a *ngIf="formData" (click)="goToPayRedsys()">{{'continue' | i18n}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="km-item km-blue" *ngIf="nextRate == null">
        <p class="title"> {{'upgradeSub' | i18n }}</p>
        <div class="horizontal-line-first horizontal-line-first-blue-item "></div>
        <!-- <p class="buy-subcription" >{{'ksub' | i18n }} {{nextRate.kms}} KM</p> -->
        <p class="buy-subcription">Suscripcion 1000 KM</p>
        <div class="eurosv2">
          <!-- <p> {{nextRate.price}}</p> -->
          <p>499,95</p>
          <span>€/mes</span>
        </div>

        <div class="horizontal-line-second horizontal-line-second-blue-item "></div>
        <div class="taxes">
          <p>*{{'taxesIncluded' | i18n }}</p>
        </div>
        <div class="button-container">
          <div class="continue-button">
            <a href="/profile/payment-steps/{{nextRate.id}}/{{projectId}}">Continuar</a>
            <!-- <a href="/profile/payment-steps/{{nextRate.id}}/{{projectId}}">{{'continue' | i18n}}</a> -->
          </div>
        </div>
      </div>


      <div class="km-item km-green">
        <p class="title" [innerHTML]=" 'equalKm'| i18n "></p>
        <div class="horizontal-line-first horizontal-line-first-green-item"></div>
        <p class="description-green">{{'equalKm2' | i18n }}</p>
        <div class="horizontal-line-second horizontal-line-second-green-item"></div>

        <div class="button-container-description">
          <div class="continue-button" [ngClass]="{ 'loading-btn': !userRateId }">
            <a *ngIf="!userRateId">{{'loading' | i18n}}</a>
            <a *ngIf="userRateId" href="/profile/payment-steps/{{userRateId}}/{{projectId}}">{{'continue' | i18n}}</a>
          </div>
        </div>
      </div>



      <div class="km-item km-purple">
        <p class="title title-purple"> {{'deleteKm' | i18n }}</p>
        <div class="horizontal-line-first horizontal-line-first-purple-item"></div>
        <p class="description-purple">{{'deleteKm2' | i18n }}</p>
        <div class="horizontal-line-second horizontal-line-second-purple-item"></div>

        <div class="button-container-description">
          <div class="continue-button">
            <a (click)="discardVideos()">{{'continue' | i18n}}</a>
          </div>
        </div>

      </div>


    </div>
  </mat-dialog-content>
</div>