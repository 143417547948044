import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ResponsiveService } from "src/app/core/services/responsive.service";

/** Componente de la barra de navegación */
@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  /** Revisa el background */
  navbarBackgroundBlue = false;
  /** Variable para mostrar backround */
  showHeaderBackground = false;
  /** Mostrar el header */
  showHeader = false;
  /** Mostrar el footer */
  showFooter = false;
  /** header grande para ciertas pantallas */
  bigHeader = false;
  /** Header para la galería */
  galleryHeader = false;
  /** Revisa si la pantalla es móvil */
  isMobile= false;
  /** Variable para cambiar el padding del menú de la versión móvil */
  isMobileMenu = false;

  /**
   * Constructor
   * @ignore
   */
  constructor(private router: Router, private responsiveService: ResponsiveService) {
    router.events.subscribe((event) => {

      if(this.responsiveService.mobileG()){
        this.isMobile=true;
      }

      if (event instanceof NavigationEnd) {
        const url: string = event.url;
        this.navbarBackgroundBlue =
          url.includes("home") ||
          url.includes("profile") ||
          (window.innerWidth < 1024 && url === "/new-project") ||
          (window.innerWidth >= 1536 &&
            window.innerWidth < 1920 &&
            url === "/new-project");

        this.bigHeader =
          (url.includes("new-project") && url !== "/new-project")
          || url.includes("terms-of-use") || url.includes("privacy-policy") || url.includes("cookie-policy") || url.includes("research-development");

        this.galleryHeader = (url.includes("gallery") || url.includes("project-management") || url.includes("projects-processing"));

        if (
          url.includes("report-pdf") ||
          url.includes("video-report") ||
          url.includes("processing-report") ||
          url.includes("download-report") ||
          url.includes("profile") && this.isMobile

        ) {
          this.showHeader = false;
          this.showFooter = false;
        } else {
          this.showHeader = true;
          this.showFooter = true;
        }

        this.isMobileMenu = url.includes("profile") && this.isMobile;
      }
    });
  }

  /** Inicializa el componente  */
  ngOnInit(): void {

  }


}
