<div
  class="slider-item"
  (click)="click(image.project.id)"
  *ngIf="image.project"
  [style.background-image]="'url(' + image.src + ')'"
  (error)="setDefaultImage($event)"
>
  <!-- <div class="wrapper"> -->
  <div
    class="opacity-div"
    [ngClass]="{ 'failed-model': image.project.project_status_id === 2 }"
  ></div>

  <div class="model">
    <div class="model-name">{{ image.project.name | split: 20 }}</div>

    <div class="line"></div>
    <div class="gallery-item-user-info-container">
      <img
        [src]="avatarSrc"
        class="user-photo"
        (error)="setDefaultAvatar($event)"
      />
      <div class="gallery-item-user-info-text">
        {{ image.project.userName }} {{ image.project.userSurname }}
      </div>
    </div>
  </div>
</div>
