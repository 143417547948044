import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProjectThumbnail } from "src/app/models/project-thumbnail";

/** Compontente de la galería */
@Component({
  selector: "app-gallery-item",
  templateUrl: "./gallery-item.component.html",
  styleUrls: ["./gallery-item.component.scss"],
})
export class GalleryItemComponent {
  /** Src del avatar */
  avatarSrc = "";

  /** Thumbnail del proyecto */
  @Input() image: ProjectThumbnail;
  /** Emite el evento al hacer click */
  @Output() itemClick = new EventEmitter<any>();

  /** Constructor */
  constructor() {}

  /** Emite el evento al hacer click */
  click(code: number) {
    this.itemClick.emit(this.image.project.id);
  }

  /**
   * Establece el background de la imagen
   * @param event
   */
  setDefaultImage(event) {
    event.target.src = "/assets/images/blue-background.png";
  }

  /**
   * Establece el avatar
   * @param event
   */
  setDefaultAvatar(event) {
    event.target.src = "/assets/images/gallery-item-default-avatar.png";
  }
}
