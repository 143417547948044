export const APP_I18N_ENTRIES = {
  unknownErrorAccessingServer: {
    en: '',
    es: '',
    pt: '',
    fr: '',
    eu: '',
  },
  authTitle: {
    en: 'Artificial intelligence for road inventories.',
    es: 'Inteligencia artificial para inventarios viales.',
    pt: 'Inteligência artificial para inventários rodoviários.',
    fr: '',
    eu: '',
  },
  login: {
    en: 'Log in',
    es: 'Inicio de sesión',
    pt: 'Iniciar sessão',
    fr: '',
    eu: '',
  },
  username: {
    en: 'Username',
    es: 'Usuario',
    pt: 'Utilizador',
    fr: '',
    eu: '',
  },
  password: {
    en: 'Password',
    es: 'Contraseña',
    pt: 'Palavra-passe',
    fr: '',
    eu: '',
  },
  loginLabel: {
    en: 'START',
    es: 'COMENZAR',
    pt: 'COMEÇAR',
    fr: '',
    eu: '',
  },
  passwordRequired: {
    en: 'Password required',
    es: 'La contraseña es obligatoria',
    pt: 'O campo \"palavra-passe\" é obrigatório',
    fr: '',
    eu: '',
  },
  yourFirstTime: {
    en: 'Your first time?',
    es: '¿Es tu primera vez?',
    pt: 'É a sua primeira vez?',
    fr: '',
    eu: '',
  },
  createAccount: {
    en: 'Create a free account',
    es: 'Crea tu cuenta gratis',
    pt: 'Crie uma conta gratuita',
    fr: '',
    eu: '',
  },
  connectWith: {
    en: 'Connect with ',
    es: 'Conectarse con ',
    pt: 'Aceder com ',
    fr: '',
    eu: '',
  },
  facebook: {
    en: 'Facebook',
    es: 'Facebook',
    pt: 'Facebook',
    fr: '',
    eu: '',
  },
  authTitleDesktop2: {
    en: 'Prevention and innovation',
    es: 'Prevención e innovación',
    pt: 'Prevenção e inovação',
    fr: '',
    eu: '',
  },
  authTitleDesktop3: {
    en: 'innovation',
    es: 'innovación',
    pt: 'inovação',
    fr: '',
    eu: '',
  },
  authTitleDesktop4: {
    en: 'Artificial intelligence for',
    es: 'Inteligencia artificial para',
    pt: 'Inteligência artificial para',
    fr: '',
    eu: '',
  },
  authTitleDesktop5: {
    en: 'road inventories.',
    es: 'inventarios viales.',
    pt: 'inventários rodoviários.',
    fr: '',
    eu: '',
  },
  start: {
    en: 'Start',
    es: 'Comenzar',
    pt: 'Começar',
    fr: '',
    eu: '',
  },
  termsConditionsRequired: {
    en: 'You must accept the terms and conditions',
    es: 'Debes aceptar los términos y condiciones',
    pt: 'É necessário que aceite os Termos e Condições',
    fr: '',
    eu: '',
  },
  agree: {
    en: 'I agree to the',
    es: 'He leído y acepto los',
    pt: 'Li e aceito os',
    fr: '',
    eu: '',
  },
  ethicalRules: {
    en: 'Code of ethics',
    es: 'Código ético',
    pt: 'Código ético',
    fr: '',
    eu: '',
  },
  home: {
    en: 'Home',
    es: 'Inicio',
    pt: 'Início',
    fr: '',
    eu: '',
  },
  generation: {
    en: 'Generation',
    es: 'Generación',
    pt: 'Criação',
    fr: '',
    eu: '',
  },
  projectsInProcess: {
    en: 'Projects in progress',
    es: 'Proyectos en proceso',
    pt: 'Projetos em processamento',
    fr: '',
    eu: '',
  },
  showAll: {
    en: 'Show all',
    es: 'Mostrar todo',
    pt: 'Mostrar tudo',
    fr: '',
    eu: '',
  },
  newProjectText: {
    en: 'Upload your road project and analyze all of the signs',
    es: 'Sube tu proyecto de carretera y analiza todas sus señales',
    pt: 'Carregue o seu projeto de estrada e analise todos os seus sinais',
    fr: '',
    eu: '',
  },
  galleryText: {
    en: 'Your projects, roads, eyesNroad gallery, etc.',
    es: 'Tus proyectos, carreteras, tu galería eyesNroad...',
    pt: 'Os seus projetos, as suas estradas, a sua galeria eyesNroad…',
    fr: '',
    eu: '',
  },
  language_name: {
    en: 'Language',
    es: 'Idioma',
    pt: 'Idioma',
    fr: '',
    eu: '',
  },
  help: {
    en: 'Help',
    es: 'Ayuda',
    pt: 'Ajuda',
    fr: '',
    eu: '',
  },
  account: {
    en: 'Account',
    es: 'Cuenta',
    pt: 'Conta',
    fr: '',
    eu: '',
  },
  myAccount: {
    en: 'My account',
    es: 'Mi Cuenta',
    pt: 'A Minha Conta',
    fr: '',
    eu: '',
  },
  chatOnline: {
    en: 'Online chat',
    es: 'Chat online',
    pt: 'Chat online',
    fr: '',
    eu: '',
  },
  chatOnlineClose: {
    en: 'Close chat',
    es: 'Cerrar chat',
    pt: 'Fechar chat',
    fr: '',
    eu: '',
  },
  projectName: {
    en: 'Project name',
    es: 'Nombre del proyecto',
    pt: 'Nome do projeto',
    fr: '',
    eu: '',
  },
  rememberMe: {
    en: 'Remember me',
    es: 'Recuérdame',
    pt: 'Lembrar-me',
    fr: '',
    eu: '',
  },
  forgotPassword: {
    en: 'Forgot your password?',
    es: '¿Has olvidado tu contraseña?',
    pt: 'Esqueceu-se da sua palavra-passe?',
    fr: '',
    eu: '',
  },
  forgotPasswordIntroduction1: {
    en: 'Enter your email address and click on',
    es: 'Escribe tu correo y haz click en',
    pt: 'Insira o seu endereço de correio eletrónico e clique em',
    fr: '',
    eu: '',
  },
  forgotPasswordIntroduction2: {
    en: 'SEND ME AN EMAIL',
    es: 'OBTENER CORREO',
    pt: 'OBTER E-MAIL',
    fr: '',
    eu: '',
  },
  forgotPasswordIntroduction3: {
    en: 'We\'ll send you an email with instructions on how to reset your password.',
    es: 'Le enviaremos un email con instrucciones para restablecer su contraseña.',
    pt: 'Enviar-lhe-emos um e-mail com instruções para repor a sua palavra-passe.',
    fr: '',
    eu: '',
  },
  getCode: {
    en: 'SEND ME AN EMAIL',
    es: 'OBTENER CORREO',
    pt: 'OBTER E-MAIL',
    fr: '',
    eu: '',
  },
  reset: {
    en: 'Reset',
    es: 'Restablecer',
    pt: 'Repor',
    fr: '',
    eu: '',
  },
  forgotPasswordMessage1: {
    en: 'We have sent you an email.',
    es: 'Te hemos enviado un correo.',
    pt: 'Enviámos-lhe um e-mail.',
    fr: '',
    eu: '',
  },
  forgotPasswordMessage2: {
    en: '<p>Please check your inbox and spam folder</p><p>and follow the instructions in the email.</p>',
    es: '<p>Por favor, revisa tu bandeja de entrada y de</p><p>Spam, y sigue las instrucciones del correo.</p>',
    pt: '<p>Por favor, verifique a sua caixa de entrada e a pasta<br></p><p>de spam e siga as instruções contidas no e-mail.</p>',
    fr: '',
    eu: '',
  },
  accept: {
    en: 'Accept',
    es: 'Aceptar',
    pt: 'Aceitar',
    fr: '',
    eu: '',
  },
  cancel: {
    en: 'Cancel',
    es: 'Cancelar',
    pt: 'Cancelar',
    fr: '',
    eu: '',
  },
  email: {
    en: 'Email',
    es: 'Correo Electrónico',
    pt: 'Endereço de Correio Eletrónico',
    fr: '',
    eu: '',
  },
  passwordConfirmation: {
    en: 'Confirm password',
    es: 'Confirma la contraseña',
    pt: 'Confirme a palavra-passe',
    fr: '',
    eu: '',
  },
  newPasswordTitle: {
    en: 'Write your new password',
    es: 'Escribe tu nueva contraseña',
    pt: 'Escreva a sua nova palavra-passe',
    fr: '',
    eu: '',
  },
  newPasswordText1: {
    en: '<p>Please, write your new password</p><p>in both fields.</p>',
    es: '<p>Por favor, introduce la nueva contraseña<p></p><p>en ambos campos.</p>',
    pt: '<p>Por favor, escreva a sua nova palavra-passe</p><p>nos dois campos.</p>',
    fr: '',
    eu: '',
  },
  newPasswordText2: {
    en: '<p>Then, click on <span class="i18n-bold">RESET</span></p><p><span class="i18n-bold">PASSWORD</span> and you will be able to register with</p><p>the new password.</p>',
    es: '<p>Después, haz click en <span class="i18n-bold">RESTABLECER</span></p><p><span class="i18n-bold">CONTRASEÑA</span> y podras registrarte con la</p><p>nueva contraseña.</p>',
    pt: '<p>Depois, clique em <span class="i18n-bold">REPOR</span></p><p><span class="i18n-bold">PALAVRA-PASSE</span> e poderá registar-se com a</p><p>nova palavra-passe.</p>',
    fr: '',
    eu: '',
  },
  newPasswordText3: {
    en: 'RESET PASSWORD',
    es: 'RESTABLECER CONTRASEÑA',
    pt: 'REPOR PALAVRA-PASSE',
    fr: '',
    eu: '',
  },
  newPasswordText4: {
    en: 'and you\'ll be able to log in with your new password.',
    es: 'y podrás registrarte con la nueva contraseña.',
    pt: 'e poderá iniciar sessão com a nova palavra-passe.',
    fr: '',
    eu: '',
  },
  resetPassword: {
    en: 'Reset password',
    es: 'Restablece la contraseña',
    pt: 'Reponha a palavra-passe',
    fr: '',
    eu: '',
  },
  resetPassword2: {
    en: 'Reset password',
    es: 'Restablecer contraseña',
    pt: 'Repor palavra-passe',
    fr: '',
    eu: '',
  },
  date: {
    en: 'Date',
    es: 'Fecha',
    pt: 'Data',
    fr: '',
    eu: '',
  },
  customerService: {
    en: 'Customer Service',
    es: 'Atención al Cliente',
    pt: 'Atenção ao Cliente',
    fr: '',
    eu: '',
  },
  address: {
    en: 'address',
    es: 'dirección',
    pt: 'morada',
    fr: '',
    eu: '',
  },
  estimatedTime: {
    en: 'Estimated processing time',
    es: 'Tiempo estimado de proceso',
    pt: 'Tempo estimado de processamento',
    fr: '',
    eu: '',
  },
  yourProjectsInProcess: {
    en: 'Your projects in progress',
    es: 'Tus proyectos en proceso',
    pt: 'Os seus projetos em processamento',
    fr: '',
    eu: '',
  },
  myProjects: {
    en: 'My projects',
    es: 'Mis proyectos',
    pt: 'Os meus projetos',
    fr: '',
    eu: '',
  },
  projects: {
    en: 'Projects',
    es: 'Proyectos',
    pt: 'Projetos',
    fr: '',
    eu: '',
  },
  allProcessingProjects: {
    en: 'All your project in progress',
    es: 'Todos tus proyectos en proceso',
    pt: 'Todos os seus projetos em processamento',
    fr: '',
    eu: '',
  },
  goToProject: {
    en: 'Go to project',
    es: 'Ir al proyecto',
    pt: 'Ir para o projeto',
    fr: '',
    eu: '',
  },
  project: {
    en: 'Project',
    es: 'Proyecto',
    pt: 'Projeto',
    fr: '',
    eu: '',
  },
  noProjectsMainText: {
    en: 'You currently have no completed projects',
    es: 'Actualmente no tiene proyectos realizados',
    pt: 'Atualmente, não tem projetos criados',
    fr: '',
    eu: '',
  },
  noProjectsSecondaryText: {
    en: 'Generate a new road project and analyze all of the signs.',
    es: 'Genera un nuevo proyecto de carretera y analiza todas sus señales.',
    pt: 'Crie um novo projeto de estrada e analise todos os seus sinais.',
    fr: '',
    eu: '',
  },
  emptyPayments: {
    en: 'No payments have been made.',
    es: 'No se han realizado pagos.',
    pt: 'Não foram efetuados pagamentos.',
    fr: '',
    eu: '',
  },
  emptyPaymentsDesc1: {
    en: 'It\'s time to adapt the rate to your needs.',
    es: 'Es momento de personalizar la tarifa a tus necesidades.',
    pt: 'Está na hora de personalizar a tarifa de acordo com as suas necessidades.',
    fr: '',
    eu: '',
  },
  emptyPaymentsDesc2: {
    en: 'Generate road inventories with adapted plans and subscriptions.',
    es: 'Genera inventarios de tus carreteras con bonos y planes adaptados.',
    pt: 'Crie inventários das suas estradas com vales e planos personalizados.',
    fr: '',
    eu: '',
  },
  emptyPaymentButton: {
    en: 'SELECT YOUR RATE',
    es: 'SELECCIONA TU TARIFA',
    pt: 'SELECIONE A SUA TARIFA',
    fr: '',
    eu: '',
  },
  road: {
    en: 'Road',
    es: 'Carretera',
    pt: 'Estrada',
    fr: '',
    eu: '',
  },
  inspectionType: {
    en: 'Type of inspection',
    es: 'Tipo de inspección',
    pt: 'Tipo de inspeção',
    fr: '',
    eu: '',
  },
  rol: {
    en: 'Role',
    es: 'Rol',
    pt: 'Cargo',
    fr: '',
    eu: '',
  },
  department: {
    en: 'Department',
    es: 'Departamento',
    pt: 'Departamento',
    fr: '',
    eu: '',
  },
  gender: {
    en: 'Gender',
    es: 'Sexo',
    pt: 'Género',
    fr: '',
    eu: '',
  },
  birthDate: {
    en: 'Date of birth',
    es: 'Fecha de nacimiento',
    pt: 'Data de nascimento',
    fr: '',
    eu: '',
  },
  haveAccount: {
    en: 'Do you have an account?',
    es: '¿Tienes una cuenta?',
    pt: 'Tem uma conta?',
    fr: '',
    eu: '',
  },
  loginNow: {
    en: 'Log in',
    es: 'Inicia sesión',
    pt: 'Inicie sessão',
    fr: '',
    eu: '',
  },
  downloadAllPayments: {
    en: 'Download all invoices',
    es: 'Descargar todas las facturas',
    pt: 'Transferir todas as faturas',
    fr: '',
    eu: '',
  }
};
