export const COMMONS_I18N_ENTRIES = {
  gallery: {
    en: "Gallery",
    es: "Galería",
    pt: "Galeria",
    fr: "",
    eu: "",
  },
  proyectGallery: {
    en: "Project Gallery",
    es: "Galería de proyectos",
    pt: "Galeria de projetos",
    fr: "",
    eu: "",
  },
  loading: {
    en: "Loading",
    es: "Cargando",
    pt: "A carregar",
    fr: "",
    eu: "",
  },
  company: {
    en: "company",
    es: "empresa",
    pt: "empresa",
    fr: "",
    eu: "",
  },
  professional: {
    en: "professional",
    es: "profesional",
    pt: "profissional",
    fr: "",
    eu: "",
  },
  project_management: {
    en: "Manage your projects",
    es: "Gestión de proyectos",
    pt: "Gestão de projetos",
    fr: "",
    eu: "",
  },
  projectsInProcess: {
    en: "Projects in progress",
    es: "Proyectos en proceso",
    pt: "Projetos em processamento",
    fr: "",
    eu: "",
  },
  user: {
    en: "User",
    es: "Usuario",
    pt: "Utilizador",
    fr: "",
    eu: "",
  },
  viewMore: {
    en: "see more",
    es: "ver más",
    pt: "ver mais",
    fr: "",
    eu: "",
  },
  nameRequired: {
    en: "Name required",
    es: "El nombre es obligatorio",
    pt: 'O campo "Nome" é obrigatório',
    fr: "",
    eu: "",
  },
  name: {
    en: "Name",
    es: "Nombre",
    pt: "Nome",
    fr: "",
    eu: "",
  },
  placeholderName: {
    en: "Name / Category",
    es: "Nombre / Categoría",
    pt: "",
    fr: "",
    eu: "",
  },
  emailRequired: {
    en: "Email required",
    es: "El correo electrónico es obligatorio",
    pt: 'O campo "Endereço de correio eletrónico" é obrigatório',
    fr: "",
    eu: "",
  },
  surname: {
    en: "Surname *",
    es: "Apellidos *",
    pt: "Apelidos *",
    fr: "",
    eu: "",
  },
  save: {
    en: "save",
    es: "guardar",
    pt: "guardar",
    fr: "",
    eu: "",
  },
  cancel: {
    en: "Cancel",
    es: "Cancelar",
    pt: "Cancelar",
    fr: "",
    eu: "",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
    pt: "Continuar",
    fr: "",
    eu: "",
  },
  models: {
    en: "models",
    es: "modelos",
    pt: "modelos",
    fr: "",
    eu: "",
  },
  badEmail: {
    en: "Incorrect email",
    es: "Correo erróneo",
    pt: "Endereço de correio eletrónico incorreto",
    fr: "",
    eu: "",
  },
  minPasswordError: {
    en: "Your password must contain more than 4 characters",
    es: "La contraseña tiene que tener más de 4 caracteres",
    pt: "A palavra-passe tem de ter mais de 4 carateres",
    fr: "",
    eu: "",
  },
  notEqualPasswords: {
    en: "The passwords do not match",
    es: "La contraseñas no coinciden",
    pt: "As palavras-passe não coincidem",
    fr: "",
    eu: "",
  },
  followUsIn: {
    en: "Follow us on",
    es: "Síguenos en",
    pt: "Siga-nos no",
    fr: "",
    eu: "",
  },
  termsOfUse: {
    en: "Terms of use",
    es: "Términos de uso",
    pt: "Termos de utilização",
    fr: "",
    eu: "",
  },
  privacy: {
    en: "Privacy",
    es: "Privacidad",
    pt: "Privacidade",
    fr: "",
    eu: "",
  },
  privacyPolicy: {
    en: "Privacy Policy",
    es: "Política de Privacidad",
    pt: "Política de Privacidade",
    fr: "",
    eu: "",
  },
  cookiePolicies: {
    en: "Cookie policies",
    es: "Políticas de cookies",
    pt: "Política de Cookies",
    fr: "",
    eu: "",
  },
  projectIplusD: {
    en: "R+D Projects",
    es: "R & D Projects",
    pt: "Projetos I+D",
    fr: "",
    eu: "",
  },
  downloadApp: {
    en: "Download app",
    es: "Descarga la aplicación",
    pt: "Transfira a aplicação",
    fr: "",
    eu: "",
  },
  delete: {
    en: "Delete",
    es: "Eliminar",
    pt: "Eliminar",
    fr: "",
    eu: "",
  },
  footerText: {
    en: " eyesNroad.com is an ecaptureDtech product. eyesNroad works with an IOTA Tangle security protocol",
    es: " eyesNroad.com es un producto de ecaptureDtech. eyesNroad trabaja con protocolo de seguridad IOTA Tangle",
    pt: " eyesNroad.com é um produto da ecaptureDtech. eyesNroad trabalha com protocolo de segurança IOTA Tangle",
    fr: "",
    eu: "",
  },
  searcher: {
    en: "Search engine",
    es: "Buscador",
    pt: "Motor de pesquisa",
    fr: "",
    eu: "",
  },
  searcherExit: {
    en: "Exit search engine",
    es: "Salir del buscador",
    pt: "Sair do motor de pesquisa",
    fr: "",
    eu: "",
  },
  noResultsFound: {
    en: "No results found for",
    es: "No se han encontrado resultados para",
    pt: "Não foram encontrados resultados para",
    fr: "",
    eu: "",
  },
  then: {
    en: "Next",
    es: "A continuación",
    pt: "A seguir",
    fr: "",
    eu: "",
  },
  searchResultText: {
    en: "Search results for",
    es: "Resultado de la búsqueda de",
    pt: "Resultado da pesquisa para",
    fr: "",
    eu: "",
  },
  newProject: {
    en: 'New project',
    es: 'Nuevo Proyecto',
    pt: 'Novo projeto',
    fr: '',
    eu: '',
  },
  myPortfolio: {
    en: "My portfolio",
    es: "Mi portafolio",
    pt: "O meu portfólio",
    fr: "",
    eu: "",
  },
  myLast: {
    en: "My latest",
    es: "Mis últimos",
    pt: "Os meus últimos",
    fr: "",
    eu: "",
  },
  myFavorites: {
    en: "My favorites",
    es: "Mis favoritos",
    pt: "Os meus favoritos",
    fr: "",
    eu: "",
  },
  myProjects: {
    en: "My projects",
    es: "Mis proyectos",
    pt: "Os meus projetos",
    fr: "",
    eu: "",
  },
  myShared: {
    en: "My shared projects",
    es: "Mis Compartidos",
    pt: "Os meus partilhados",
    fr: "",
    eu: "",
  },
  excessKm: {
    en: "Excess kilometers",
    es: "Exceso Km",
    pt: "Excesso km",
    fr: "",
    eu: "",
  },
  excessKmsub: {
    en: "in your subscription",
    es: "en tu suscripción",
    pt: "na sua subscrição",
    fr: "",
    eu: "",
  },
  whatdouwtd: {
    en: "What do you want to do?",
    es: "¿Qué desea hacer?",
    pt: "O que pretende fazer?",
    fr: "",
    eu: "",
  },
  additionalCost: {
    en: "Cost per additional km",
    es: "Coste por </br> km adicional",
    pt: "Custo por km adicional",
    fr: "",
    eu: "",
  },
  upgradeSub: {
    en: "Upgrade subscription",
    es: "Actualizar suscripción",
    pt: "Atualizar subscrição",
    fr: "",
    eu: "",
  },
  equalKm: {
    en: "Compensate excess kilometers with next month's subscription",
    es: "Compensar Km con suscripción mes próximo",
    pt: "Compensar km com a subscrição do mês seguinte",
    fr: "",
    eu: "",
  },
  equalKm2: {
    en: "Compensate excess kilometers with next month's kilometers",
    es: "Compensar Km excedidos con Km mes próximo",
    pt: "Compensar km excedidos com km do mês seguinte",
    fr: "",
    eu: "",
  },
  deleteKm: {
    en: "Delete excess kilometers",
    es: "Eliminar KM excedidos",
    pt: "Eliminar km excedidos",
    fr: "",
    eu: "",
  },
  deleteKm2: {
    en: "Delete excess kilometers from the road study",
    es: "Eliminar km restantes del Estudio de carretera",
    pt: "Eliminar km restantes do Estudo de estrada",
    fr: "",
    eu: "",
  },
  taxesIncluded: {
    en: "taxes included",
    es: "impuestos incluidos",
    pt: "impostos incluídos",
    fr: "",
    eu: "",
  },
  ksub: {
    en: "SUBSCRIPTION",
    es: "SUSCRIPCIÓN",
    pt: "SUBSCRIÇÃO",
    fr: "",
    eu: "",
  },
  calculating: {
    en: "Calculating",
    es: "Calculando",
    pt: "A calcular",
    fr: "",
    eu: "",
  },
  home_data1: {
    en: "",
    es: "Perfil",
    pt: "",
    fr: "",
    eu: "",
  },
};
