import { Injectable } from '@angular/core';
import { Language } from '../../models/language';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import { TranslationModeEnum } from 'src/app/commons/enum/translation-mode-enum';
import { StorageService } from 'src/app/services/storage/storage.service';

/**
 * Servicio para la internacionalización.
 */
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  /** Variable para el código de idioma */
  private currentLanguageCode: string;
  /** Obtiene las entradas */
  private i18nEntriesObjects: any[] = [];

  /**
   * Constructor
   * @ignore
   */
  constructor(private storageService: StorageService) {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localePt, 'pt');
    try {
      console.log(navigator.language);
      this.currentLanguageCode = I18nService.getSupportedLanguages().find(
        (lang) => navigator.language.includes(lang.code)
      ).code;
    } catch (e) {
      this.currentLanguageCode = I18nService.getSupportedLanguages()[0].code;
    }
  }

  /**
   * Obtiene los idiomas soportados.
   * @returns
   */
  public static getSupportedLanguages(): Language[] {
    return [
      new Language('es', 'Español', 'es-ES,es;q=0.8'),
      new Language('en', 'English', 'en-EN,es;q=0.8'),
      new Language('pt', 'Português', 'pt-PT,es;q=0.8'),
      new Language('fr', 'Français', 'fr-FR,es;q=0.8'),
      new Language('eus', 'Euskera', 'eu-EU,es;q=0.8'),
      new Language('cat', 'Català', 'ca-ES,es;q=0.8'),
    ];
  }

  /**
   * Obtiene el valor de la clave de internacionalización.
   * @param value
   * @returns
   */
  public static getValueI18nKey(value: string): string {
    return value[0].toLowerCase() + value.slice(1);
  }

  /**
   * Establece el idioma.
   * @param languageCode
   */
  public setCurrentLanguage(languageCode: string): void {
    const isCodeSupported = I18nService.getSupportedLanguages().some(
      (x) => x.code === languageCode
    );

    if (!isCodeSupported) {
      languageCode = 'es';
    }

    this.currentLanguageCode = languageCode;
    this.storageService.set('enr-language', this.currentLanguageCode);
  }

  /**
   * Obtiene el idioma actual.
   * @returns
   */
  public getCurrentLanguage(): Language {
    return I18nService.getSupportedLanguages().find(
      (x) => x.code === this.currentLanguageCode
    );
  }

  /**
   * Obtiene el texto y lo procesa
   * @param key
   * @param keyParams
   * @returns
   */
  public getText(key: string, keyParams?: any): string {
    if (!key) {
      return '';
    }
    const keyFirstCharLowered = I18nService.getValueI18nKey(key);
    const i18nEntriesObject = this.i18nEntriesObjects.find(
      (x) => x[keyFirstCharLowered] !== undefined
    );

    if (!i18nEntriesObject) {
      if (!i18nEntriesObject) {
        return '--';
      }
    }

    const entry = i18nEntriesObject[keyFirstCharLowered];
    let text = entry[this.currentLanguageCode];

    if (!text) {
      throw new Error(
        `Inexistent language '${this.currentLanguageCode}' for i18n entry '${key}'.`
      );
    }

    if (keyParams) {
      // Object.keys(keyParams).forEach((keyParam) => text = text.replace(`$${keyParam}`, keyParams[keyParam]));
      let textArray;
      let translationMode = keyParams;
      if (keyParams.length > 1) {
        translationMode = keyParams[0];
      }
      switch (translationMode) {
        case TranslationModeEnum.PLACEHOLDER:
          textArray = text.match(/\>(.*?)\</g);
          text = textArray[0].substring(1, textArray[0].length - 1);
          break;
        case TranslationModeEnum.TOOLTIP:
          textArray = text.match(/\>(.*?)\</g);
          text = textArray[0].substring(1, textArray[0].length - 1);
          break;
        case TranslationModeEnum.VIEWER:
          textArray = text.match(/\>(.*?)\</g);
          text = textArray[0].substring(1, textArray[0].length - 1);
          break;
        case TranslationModeEnum.INSERT_VALUE:
          text = text.replace(/%X%/g, keyParams[1]);
          break;
        default:
          break;
      }
    }
    return text;
  }

  /**
   * Busca el texto y lo procesa
   * @param i18nEntriesObject
   */
  public addI18nEntriesObject(i18nEntriesObject: any): void {
    this.i18nEntriesObjects.push(i18nEntriesObject);
  }
}
