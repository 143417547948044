import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RestClientErrorHandlerService } from './rest-client-error-handler.service';
import { SecurityTokenStorage } from '../../../services/security/security-token-storage';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../../../services/config/app-config.service';
import { I18nService } from '../i18n.service';
import { UserToken } from '../../../models/security/user-token';
import { DateService } from '../../../services/api-services/date.service';
import { environment } from 'src/environments/environment';

/**
 * Servicio para hacer llamadas REST al backend.
 */
@Injectable({
  providedIn: 'root',
})
export class RestClientService {
  /**
   * Constructor.
   * @ignore
   */
  constructor(
    private httpClient: HttpClient,
    private i18nService: I18nService,
    private restClientErrorHandler: RestClientErrorHandlerService,
    private appConfigService: AppConfigService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>
  ) {}

  /**
   * Obtiene el token de seguridad.
   * @param url
   * @param params
   * @returns
   */
  public get<T>(url: string, params?: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .get<T>(completeUrl, this.getRequestOptions(params))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  /**
   * Función post
   * @param url
   * @param data
   * @param httpHeaders
   * @returns
   */
  public post<T>(
    url: string,
    data: any,
    httpHeaders?: HttpHeaders
  ): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .post<T>(completeUrl, data, this.getRequestOptions({}, httpHeaders))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  /**
   * Función put
   * @param url
   * @param data
   * @returns
   */
  public put<T>(url: string, data: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .put<T>(completeUrl, data, this.getRequestOptions({}))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  /**
   * Función delete
   * @param url
   * @param params
   * @returns
   */
  public delete<T>(url: string, params?: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .delete<T>(completeUrl, this.getRequestOptions(params))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  /**
   * Función getBlob
   * @param url
   * @param params
   * @returns
   */
  public getBlob<T>(url: string, params?: any): Observable<Blob> {
    return this.httpClient
      .get(this.getCompleteUrl(url), {
        responseType: 'blob',
        headers: this.getHttpHeaders(null),
        params: this.getHttpParams(params),
      })
      .pipe(
        catchError((error) =>
          this.restClientErrorHandler.handleBlobError(error)
        )
      );
  }

  /**
   * Obtiene texto
   * @param url
   * @param params
   * @returns
   */
  public getText<T>(url: string, params?: any): Observable<string> {
    return this.httpClient
      .get(this.getCompleteUrl(url), {
        responseType: 'text',
        headers: this.getHttpHeaders(null),
        params: this.getHttpParams(params),
      })
      .pipe(
        catchError((error) =>
          this.restClientErrorHandler.handleBlobError(error)
        )
      );
  }

  /**
   * obtiene url completa
   * @param url
   * @returns
   */
  private getCompleteUrl(url: string): string {
    return url.length > 0 && url[0] === '/'
      ? `${environment.apiUrl}${url}`
      : `${environment.apiUrl}/${url}`;
  }

  /**
   * Obtiene opciones de petición
   * @param params
   * @param httpHeaders
   * @returns
   */
  private getRequestOptions(params: any, httpHeaders?: HttpHeaders) {
    return {
      headers: this.getHttpHeaders(httpHeaders),
      params: this.getHttpParams(params),
    };
  }

  /**
   * Obtiene headers
   * @param httpHeaders
   * @returns
   */
  private getHttpHeaders(httpHeaders: HttpHeaders): HttpHeaders {
    httpHeaders = httpHeaders || new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );

    const acceptedLogin = this.securityTokenStorage.getObjectValue();
    if (acceptedLogin && acceptedLogin.token) {
      httpHeaders = httpHeaders.set('Authorization', acceptedLogin.token);
    }
    return httpHeaders;
  }

  /**
   * Otiene los parámetros de la petición
   * @param params
   * @returns
   */
  private getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      const keys: any[] = Object.keys(params);
      const values: any[] = Object.values(params);
      values.forEach((value, index) => {
        if (values[index] !== undefined && values[index] !== null) {
          if (values[index] instanceof Date) {
            httpParams = httpParams.set(
              keys[index].toString(),
              DateService.getIsoStringDatePart(values[index].toDate())
            );
          } else {
            httpParams = httpParams.set(
              keys[index].toString(),
              values[index].toString()
            );
          }
        }
      });
    }
    return httpParams;
  }
}
