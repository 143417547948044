import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../../core/services/i18n.service';

/**
 * Pipe de traducción
 */
@Pipe({
  name: 'i18n',
  pure: false,
})
export class I18nPipe implements PipeTransform {
  /**
   * Constructor.
   * @ignore
   */
  constructor(private i18nService: I18nService) {}

  /**
   * Traducir una cadena.
   * @param value
   * @param keyParams
   * @returns
   */
  public transform(value: any, keyParams?: any): string {
    return this.i18nService.getText(value, keyParams);
  }
}
