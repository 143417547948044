<div class="navbar-container">
  <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/Grupo 7526.svg" />
  <!-- <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/navbar/white/eyesnroad-logo.svg"
    *ngIf="!backgroundBlue" /> -->

  <div class="eyescloud-navbar" [ngClass]="{
      'navbar-background-blue': true
     
    }">
    <img routerLink="/home" class="eyesnroad-logo-img" src="/assets/images/Grupo 7526.svg" />
    <!-- <img routerLink="/home" class="eyesnroad-logo-img display-pc" src="/assets/images/eyesnroad-logo-text-blue.svg"
      *ngIf="!backgroundBlue" /> -->

    <a routerLink="/profile/personal-data" class="navbar-menu-link">
      <img class="img-menu" src="/assets/images/Grupo 9204.svg" />
      <!-- <img class="img-menu" src="/assets/images/Grupo 9204.svg" *ngIf="!backgroundBlue" /> -->
    </a>
    <div class="eyescloud-navbar-menu">
      <a class="text-link text-link-first" routerLink="/new-project" [innerHTML]="'navbar_Data1' | i18n">
        <!-- {{ "newProject" | i18n }} -->
      </a>
      <a class="text-link text-link-mid" routerLink="/gallery" [innerHTML]="'navbar_Data2' | i18n">
        <!-- {{ "gallery" | i18n }} -->
      </a>
      <a (click)="onEventEmit()" class="text-link text-link-last" routerLink="/profile/user-rates"
        [innerHTML]="'navbar_Data3' | i18n">
        <!-- {{ "account" | i18n }} -->
      </a>
      <!-- ! fin text -->
      <div class="line-separator" [ngClass]="{ 'line-separator-blue': !backgroundBlue }"></div>
      <app-language-selector [footer]="false" class="language-selector"></app-language-selector>
      <div class="line-separator" [ngClass]="{ 'line-separator-blue': !backgroundBlue }"></div>
      <img class="logout-icon" src="/assets/images/Grupo 5122.svg" (click)="logout()" />
      <a (click)="chatSwitcher()" class="chat-online-button" [innerHTML]="'navbar_Data4' | i18n">
        <!-- {{ chatTitle }} -->
      </a>
    </div>
  </div>
</div>