import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { PaymentOkComponent } from './components/payments/payment-ok/payment-ok.component';
import { CoreModule } from './core/core.module';
import {
  APP_BASE_HREF,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { APP_I18N_ENTRIES } from './config/app-i18n-entries';
import { PaymentKoComponent } from './components/payments/payment-ko/payment-ko.component';
import { InformationPageComponent } from './components/information-page/information-page.component';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './config/app.routes';
import { CommonsModule } from './commons/commons.module';
import { I18nService } from './core/services/i18n.service';
import { AppConfigLoaderService } from './services/config/app-config-loader.service';
import { CustomPreloadingStrategy } from './core/services/custom-preloading-strategy';
import { UiModule } from './ui.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { ErrorInterceptor } from './services/security/error-interceptor';
import { HOME_I18N_ENTRIES } from './view-project/config/home-i18n-entries';
import { LOGIN_I18N_ENTRIES } from './config/login-i18n-entries';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PaymentOkComponent,
    PaymentKoComponent,
    InformationPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    CommonsModule,
    UiModule,
    SocialLoginModule,
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
    /*ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),*/
  ],
  exports: [],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   deps: [AppConfigLoaderService],
    //   useFactory: apiConfigProvider,
    //   multi: true,
    // },
    /*{ provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },*/
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    CustomPreloadingStrategy,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2442240265789461'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(APP_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(HOME_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(LOGIN_I18N_ENTRIES);
  }
}
/**
 * Esta función se utiliza para cargar la configuración de la aplicación
 * @param appConfigLoaderService
 * @returns
 */
export function apiConfigProvider(
  appConfigLoaderService: AppConfigLoaderService
) {
  return () => appConfigLoaderService.loadConfiguration();
}
