import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import { delay } from 'rxjs/operators';

/** Componente de loader */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  /** Revisa si está cargando */
  isLoading: boolean;
  /** Altura de la ventana */
  windowHeight: number;

  /**
   * Constructor
   * @param loaderService
   */
  constructor(private loaderService: LoaderService) {}

  /**
   * Inicializa el componente
   */
  ngOnInit(): void {
    // this.loaderService
    //   .isLoading()
    //   .pipe(delay(0))
    //   .subscribe((isLoading) => {
    //     this.windowHeight = window.innerHeight;
    //     this.isLoading = isLoading;
    //   });
  }
}
