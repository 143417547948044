import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { _ } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ProjectRemoteService } from 'src/app/core/services/remote/project/project-remote.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { User } from 'src/app/shared/models/user/user';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { EmailValidator } from '@angular/forms';

/** Componente de exceso de km */
@Component({
  selector: 'app-two-factor-dialog',
  templateUrl: './two-factor-dialog.component.html',
  styleUrls: ['./two-factor-dialog.component.scss'],
})
export class TwoFactorDialogComponent {
  /** Usuario actual */
  user: User;
  /** Token random */
  token: any;
  /** Variable si introducen un token erróneo */
  badToken: any;
  /** Token introducido por el usuario */
  inputToken: any;
  /** Número de teléfono del usuario */
  phone: any;
  /** Saber si no tiene teléfono en el perfil */
  noPhone = false;
  /** Últimos dos dígitos del número */
  lastDigits: any;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TwoFactorDialogComponent>,
    private storageService: StorageService,
    private responsiveService: ResponsiveService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}
  /** Evento que se ejecuta al ser creado el componente*/
  ngOnInit() {
    this.checkWindowSize();
    this.token = Math.random().toString(36).substring(2, 12);

    this.authenticationService.get().subscribe((user) => {
      this.user = user;
      this.phone = user.profile.cell_phone;

      //Lógica enviar this.token por email

      if (this.phone === null) {
        this.noPhone = true;
      } else {
        this.lastDigits = this.phone.toString().substring(7, 9);
      }
    });
  }

  /**
   * Comprueba si el token introducido es correcto
   */
  send() {
    if (this.token === this.inputToken) {
      this.dialogRef.close();
    } else {
      this.badToken = true;
    }
  }

  phoneVerification() {
    // Lógica para enviar el código de verificación por SMS
  }

  accountRecovery() {
    // Lógica para recuperar la cuenta
  }

  /**
   * Redimensiona el componente
   */
  checkWindowSize() {
    let data = [
      '300px',
      '231px',
      '300px',
      '231px',
      '380px',
      '293px',
      '380px',
      '293px',
      '300px',
      '231px',
      '380px',
      '293px',
      '380px',
      '293px',
      '380px',
      '293px',
      '380px',
      '293px',
      '471px',
      '384px',
      '502px',
      '410px',
    ];
    this.responsiveService.screenResize(data, this.dialogRef);
  }
}
