import { Component, Input, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../services/security/authentication.service";
import { LoaderService } from "../../../core/services/loader.service";
import { I18nService } from "src/app/core/services/i18n.service";
import { EventService } from 'src/app/core/services/event.service';
import { EventData } from "src/app/core/models/event-data";
import { ResponsiveService } from "src/app/core/services/responsive.service";

/** Componente para mostrar la barra de navegación */
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  /** Revisa el background */
  @Input() backgroundBlue = true;
  /** Muestra el header entero  */
  fullHeaderBar = false;
  /** Botón de chat */
  chatSwitch = false;
  /** Título */
  chatTitle = "";

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private i18nService: I18nService,
    private responsiveService: ResponsiveService,
    private eventService: EventService,
  ) {}

  /**
   * Carga al iniciar el componente
   */
  ngOnInit() {
    this.chatTitle = this.i18nService.getText("chatOnline");
  }

  /**
   * Cierra la sesión
   */
  logout() {
    this.loaderService.showLoader();
    this.authenticationService.logout();
  }

  /**
   * Muestra el chat
   */
  chatSwitcher() {
    this.chatSwitch = !this.chatSwitch;
    if (this.chatSwitch) {
      this.chatTitle = this.i18nService.getText("chatOnlineClose");
      (window as any).HubSpotConversations.widget.open();
    } else {
      (window as any).HubSpotConversations.widget.close();
      this.chatTitle = this.i18nService.getText("chatOnline");
    }
  }

  /**
   * emite un evento cuando el usuario accede a mi cuenta
   */
   onEventEmit(){
    if (this.responsiveService.isM()){
      this.eventService.emit(new EventData('rate',null));
    }
    if (this.responsiveService.moreThan1024() || this.responsiveService.specialMovilResolution()){
      this.eventService.emit(new EventData('myAccount',null));
    }
  }
}
