import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../core/services/loader.service';
import { Project } from 'src/app/models/projects/project';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { UserRemoteService } from '../../core/services/remote/user/user.remote.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { TranslationModeEnum } from 'src/app/commons/enum/translation-mode-enum';

/** Componente para la página de inicio */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  [x: string]: any;

  /**
   * Constructor de la clase.
   * @ignore
   */
  constructor(
    private router: Router,
    private i18nService: I18nService,
    private loaderService: LoaderService,
    private userRemoteService: UserRemoteService,
    private responsiveService: ResponsiveService
  ) {}

  /** Carga los proyectos del usuario */
  userProjects: Project[] = [];
  /** Carga las imágenes del slider */
  sliderImages: ProjectThumbnail[];
  /** Variable para saber si es móvil o no */
  isMobile = false;

  /** Inicializa el componente. */
  ngOnInit() {
    this.loaderService.showLoader();
    this.getUserProjects();
    this.isMobile = window.innerWidth < 600;
  }

  /** Carga proyectos del usuario. */
  private getUserProjects() {
    this.userRemoteService.getProjects(10).subscribe(
      (projects) => {
        this.userProjects = projects;
        if (this.userProjects.length > 10) {
          this.userProjects = this.userProjects.slice(0, 10);
        }
        this.loadSliderImages();
        this.loaderService.hideLoader();
      },
      (error) => this.loaderService.hideLoader()
    );
  }

  /** Carga las imágenes del slider del proyecto. */
  private loadSliderImages() {
    this.sliderImages = [];
    this.userProjects.forEach((project) => {
      this.sliderImages.push({
        name: project.name,
        src: project.url,
        project,
      });
    });
  }

  /** Navega a la página de creación de proyecto. */
  gotToNewProject() {
    this.loaderService.showLoader();
    this.router.navigateByUrl('new-project');
  }
}
