import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Project } from '../../../../models/projects/project';
import { ProjectRemoteService } from 'src/app/core/services/remote/project/project-remote.service';

/**
 * Componente para mostrar un proyecto en el slider de proyectos populares
 */
@Component({
  selector: 'app-populars-item',
  templateUrl: './populars-item.component.html',
  styleUrls: ['./populars-item.component.scss'],
})
export class PopularsItemComponent {
  /** Url del avatar */
  avatarSrc = '';
  /** Revisa si el icono de favoritos está marcado */
  favIconActive = false;

  /** Carga el proyecto */
  @Input() project: Project;
  /** Emite al dar click */
  @Output() itemClick = new EventEmitter<any>();

  constructor(private projectRemoteService: ProjectRemoteService) {}

  /**
   * Emite el evento de click
   * @param code
   */
  click(code: number) {
    this.itemClick.emit(code);
  }

  /**
   * Pone la imagen por defecto
   */
  setDefaultImage(event) {
    event.target.src = '/assets/images/blue-background.png';
  }

  /**
   * Pone el avatar por defecto
   * @param event
   */
  setDefaultAvatar(event) {
    event.target.src = '/assets/images/gallery-item-default-avatar.png';
  }

  /**
   * Añade a favoritos
   */
  addToFav() {
    this.projectRemoteService.toggleFavourite(this.project.id).subscribe({
      next: () => {
        this.project.is_favourite = !this.project.is_favourite;
      },
      error: () => {},
    });
  }
}
