import { Injectable } from '@angular/core';
import { RestClientService } from 'src/app/core/services/api-access/rest-client.service';
import { Observable } from 'rxjs';
import { Project } from 'src/app/models/projects/project';
import { Road } from '../../../../models/road';
import { SharedProject } from '../../../../models/sharedProject';

/**
 * SComponente project remote service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectRemoteService {
  /**
   * URL del servicio.
   */
  private URL = '/projects';
  /**
   * Url compartida
   */
  private sharedURL = 'shared-projects';

  /**
   * Constructor del componente.
   * @param restClientService
   */
  constructor(private restClientService: RestClientService) {}

  /**
   * Obtiene las carreteras de un proyecto.
   * @param projectId
   * @returns
   */
  public getRoads(projectId: number): Observable<Road[]> {
    return this.restClientService.get(`${this.URL}/${projectId}/roads`);
  }

  /**
   * Función para crear un proyecto.
   * @param project
   * @returns
   */
  create(project: Partial<Project>): Observable<Project> {
    return this.restClientService.post(this.URL, project);
  }

  /**
   * Función para obtener un proyecto.
   * @param id
   * @returns
   */
  get(id: number): Observable<Project> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }

  /**
   * Función para obtener los reportes
   * @param id
   * @returns
   */
  getReportsInfo(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/reports-info`);
  }

  /**
   * Función para obtener los vídeos
   * @param id
   * @returns
   */
  getVideoPdf(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/video-pdf`);
  }

  /**
   * Procesa el PDF
   * @param id
   * @returns
   */
  getProcessPdf(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/process-pdf`);
  }

  /**
   * Procesa IOTA
   * @param idProject
   * @param idInspection
   * @returns
   */
  getProcessIota(idProject: number, idInspection: number): Observable<any> {
    return this.restClientService.get(
      `${this.URL}/${idProject}/${idInspection}/get-process-iota`
    );
  }

  /**
   * Descarga el PDF
   * @param id
   * @returns
   */
  getDownloadPdf(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/download-pdf`);
  }

  /**
   * Crea un proyecto compartido
   * @param data
   * @returns
   */
  createSharedProject(data): Observable<any> {
    return this.restClientService.post(
      `${this.URL}/${data.id}/${this.sharedURL}`,
      data
    );
  }

  /**
   * Obtiene proyectos en proceso
   * @returns
   */
  public getProcessingProjects(): Observable<any> {
    return this.restClientService.get(`${this.URL}/by/processings`, null);
  }

  /**
   * Obtiene últimos proyectos
   * @returns
   */
  public getLastProjects(): Observable<Project[]> {
    return this.restClientService.get(`${this.URL}/by/last`, null);
  }

  /**
   * Obtiene los proyectos por departamento
   * @returns
   */
  public getDepartmentProjects(): Observable<Project[]> {
    return this.restClientService.get(`${this.URL}/by/departments`, null);
  }

  /**
   * Obtiene los proyectos compartidos
   * @returns
   */
  public getSharedProjects(): Observable<SharedProject[]> {
    return this.restClientService.get(`${this.sharedURL}`, null);
  }

  /**
   * Actualiza los proyectos compartidos
   * @param sharedProject
   * @returns
   */
  public updateSharedProject(
    sharedProject: SharedProject
  ): Observable<SharedProject> {
    return this.restClientService.put(
      `${this.sharedURL}/${sharedProject.id}`,
      sharedProject
    );
  }

  /**
   * Borra proyecto compartido
   * @param sharedProject
   * @returns
   */
  public deleteSharedProject(
    sharedProject: SharedProject
  ): Observable<SharedProject> {
    return this.restClientService.put(
      `${this.sharedURL}/${sharedProject.id}/delete-shared-project`,
      sharedProject
    );
  }

  /**
   * Obtiene el coste del exceso KM, tarifas
   * @param id
   * @returns
   */
  public getCostPerExceededKm(id: number): Observable<number> {
    return this.restClientService.get(`${this.URL}/${id}/exceeded-kms`);
  }

  /**
   * Descarta los videos en proceso
   * @param id
   * @returns
   */
  public discardExceededKmVideos(id: number) {
    return this.restClientService.delete(`${this.URL}/${id}/exceeded-kms`);
  }

  public toggleFavourite(projectId: number): Observable<null> {
    return this.restClientService.post(
      `${this.URL}/${projectId}/toggle-favourite`,
      null
    );
  }
}
