<div class="home-container">
  <div class="home-up-container">
    <div id="home-new3d-button" (click)="gotToNewProject()">
      <img class="new3d-icon" src="/assets/images/home/pc-button-new-project-icon.svg" />

      <div class="home-new3d-title regular-title" [innerHTML]="'home_Data1' | i18n">
        {{ "generation" | i18n }}
      </div>
      <div class="home-new3d-text regular-text" *ngIf="!isMobile" [innerHTML]="'home_Data2' | i18n ">
        {{ "newProjectText" | i18n }}
      </div>
      <img class="home-new3d-arrow arrow" src="/assets/images/home/down-arrow-icon.png" *ngIf="!isMobile" />
    </div>
    <!-- ! Segundo contenedor no movil -->
    <div *ngIf="!isMobile" id="home-gallery-button" routerLink="/gallery">
      <img class="projets-gallery-img" src="/assets/images/home/pc-button-gallery-icon.svg" />
      <div class="home-gallery-title regular-title" [innerHTML]="'home_Data3' | i18n">
        {{ "proyectGallery" | i18n }}
      </div>
      <div class="gallery-button-text regular-text" [innerHTML]="'home_Data4' | i18n">
        {{ "galleryText" | i18n }}
      </div>
      <img class="projets-gallery-arrow arrow" src="/assets/images/home/down-arrow-icon.png" />
    </div>
    <!-- ! Otros dos contenedores no movil -->

    <div *ngIf="!isMobile" id="home-right-last-container">
      <div class="home-current-models-button-no-mobile final-squares" routerLink="/projects-processing">
        <img class="projects-in-process-img square-img" src="/assets/images/home/noun_Processing_3120636.svg" />
        <div class="projects-in-process square-text" [innerHTML]="'home_Data5' | i18n">
          {{ "projectsInProcess" | i18n }}
        </div>
        <img class="right-arrow-process square-arrow" src="/assets/images/home/right-arrow-icon.svg" />
      </div>
      <div id="project-management" class="final-squares" routerLink="/project-management">
        <img class="project-management-icon square-img" src="/assets/images/home/project-management-logo.png" />
        <div class="professional-title square-text" [innerHTML]="'home_Data6' | i18n">
          {{ "project_management" | i18n }}
        </div>
        <img class="right-arrow-management square-arrow" src="/assets/images/home/Grupo 337.svg" />
      </div>
    </div>
    <div class="home-current-models-button" routerLink="/projects-processing" *ngIf="isMobile">
      <img class="home-current-models-button-img" src="/assets/images/home/Grupo 5985.png" />
    </div>
    <div id="home-right-container-mobile" *ngIf="isMobile">
      <div id="home-gallery-button" routerLink="/gallery">
        <img class="galery-icon" src="/assets/images/home/pc-button-gallery-icon.svg" />
        <div class="home-gallery-title" [innerHTML]="'home_Data7' | i18n">{{ "proyectGallery" | i18n }}</div>
      </div>
      <div class="project-management-button-container" routerLink="/project-management">
        <div class="professional-button-title" [innerHTML]="'home_Data6' | i18n">
          {{ "project_management" | i18n }}
        </div>
      </div>
    </div>
  </div>
  <div class="ad"></div>
  <div class="home-gallery-container">
    <div class="home-gallery-subtitle-container">
      <div class="home-my-projects" [innerHTML]="'home_Data8' | i18n">{{ "myProjects" | i18n }}</div>
      <a class="home-gallery-subtitle-show-all" routerLink="/gallery">
        <p [innerHTML]="'home_Data9' | i18n"></p>
        <img class="home-gallery-img" src="/assets/images/home/Grupo 627.svg" />
      </a>
    </div>
    <div class="home-gallery" *ngIf="sliderImages && sliderImages.length > 0">
      <app-gallery-slider [images]="sliderImages"> </app-gallery-slider>
    </div>
    <div class="no-projects-container" *ngIf="sliderImages && sliderImages.length === 0">
      <img src="/assets/images/home/no-projects.svg" class="no-projects-img" />
      <div class="vertical-line"></div>
      <div class="no-projects-text-container">
        <div class="main-text" [innerHTML]="'home_Data10' | i18n">{{ "noProjectsMainText" | i18n }}</div>
        <div class="secondary-text" [innerHTML]="'home_Data11' | i18n">{{ "noProjectsSecondaryText" | i18n }}</div>
      </div>
    </div>
  </div>
</div>