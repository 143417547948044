import { NgModule } from '@angular/core';
import { SliderComponent } from './components/slider/slider.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { SharedModule } from '../shared/shared.module';
import { I18nService } from '../core/services/i18n.service';
import { COMMONS_I18N_ENTRIES } from './config/commons-i18n-entries';
import { LoaderComponent } from './components/loader/loader.component';
import { GallerySliderComponent } from './components/gallery-slider/gallery-slider.component';
import { GalleryItemComponent } from './components/gallery-slider/gallery-item/gallery-item.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MarkUnmarkModelAsFavoriteComponent } from './components/mark-unmark-model-as-favorite/mark-unmark-model-as-favorite.component';
import { AdsenseModule } from 'ng2-adsense';
import { ProjectProgressBarComponent } from './components/project-progress-bar/project-progress-bar.component';
import { PopularsItemComponent } from './components/populars-slider/populars-item/populars-item.component';
import { GalleryGridComponent } from './components/gallery-grid/gallery-grid.component';
import { GoogleAdsenseComponent } from './components/google-adsense/google-adsense.component';
import { KmExcessDialogComponent } from './components/dialogs/km-excess-dialog/km-excess-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { FlexModule } from '@angular/flex-layout';
import { NAVBAR_I18N_ENTRIES } from '../view-project/config/navbar-i18n-entries';
import { FOOTER_I18N_ENTRIES } from '../view-project/config/footer-i18n-entries';
import { LENGUAGE_I18N_ENTRIES } from '../view-project/config/lenguage-i18n-entries';
import { TwoFactorDialogComponent } from './components/dialogs/two-factor-dialog/two-factor-dialog.component';

@NgModule({
  declarations: [
    SliderComponent,
    LayoutComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    ProjectProgressBarComponent,
    PopularsItemComponent,
    LoaderComponent,
    GallerySliderComponent,
    GalleryItemComponent,
    MarkUnmarkModelAsFavoriteComponent,
    GalleryGridComponent,
    GoogleAdsenseComponent,
    KmExcessDialogComponent,
    TwoFactorDialogComponent,
  ],
  imports: [
    SharedModule,
    SimplebarAngularModule,
    MatSortModule,


    AdsenseModule.forRoot({
      adClient: 'ca-pub-4671591524487127'
    }),
  ],
  exports: [
    SliderComponent,
    LayoutComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    SharedModule,
    ProjectProgressBarComponent,
    PopularsItemComponent,
    LoaderComponent,
    SimplebarAngularModule,
    GallerySliderComponent,
    AdsenseModule,
    GalleryGridComponent,
    GoogleAdsenseComponent,
    FlexModule
  ]
})
export class CommonsModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(COMMONS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(NAVBAR_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FOOTER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(LENGUAGE_I18N_ENTRIES);

  }
}
