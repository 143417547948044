<div class="gallery-container" *ngIf="projectsToShow.length > 0">
  <div class="ads-mobile">
    <app-google-adsense *ngIf="showGoogleAdsense">
    </app-google-adsense>
  </div>
  <div class="search-container" *ngIf="showSearch" [ngClass]="{'search-active-container': searchValue.length > 0}">
    <div class="search-header">
      <div class="search-input-container">
        <p class="search-title">{{'searcher' | i18n}}</p>
        <div class="search-input">
          <input type="text" placeholder="{{'placeholderName' | i18n}}" (input)="filterChange($event.target.value)" value="{{searchValue}}">
          <img _ngcontent-swd-c259="" src="/assets/images/viewproject/searcher-ico2.svg">
        </div>
      </div>
      <div class="close-button-container" *ngIf="searchValue.length > 0">
        <p>{{'searcherExit' | i18n}}:</p>
        <a (click)="closeSearch()"><img src="/assets/images/gallery/close-search-icon.png"></a>
      </div>
    </div>
    <p class="search-text" *ngIf="searchProjects.length > 0" [innerHTML]="searchTitleFound"></p>
    <div class="not-found-results" *ngIf="searchProjects.length == 0 && searchValue.length > 0">
      <img src="/assets/images/gallery/search-ico.png">
      <span class="vertical-line"></span>
      <p [innerHTML]="searchTitle"></p>
    </div>
    <div class="gallery-images-container" *ngIf="searchProjects && searchProjects.length > 0">
      <div class="gallery-images">
        <app-populars-item *ngFor="let project of searchProjects"
                           [project]="project"
                           (itemClick)="onSliderClick($event)"
                           class="search-projects-gallery">
        </app-populars-item>
      </div>
    </div>
  </div>
  <div class="gallery-images-container">
    <div class="gallery-images" *ngIf="projectsToShow && projectsToShow.length > 0">
      <app-populars-item *ngFor="let project of projectsToShow"
                         [project]="project"
                         (itemClick)="onSliderClick($event)">
      </app-populars-item>
    </div>
    <div class="ads-desktop">
      <app-google-adsense *ngIf="showGoogleAdsense">
      </app-google-adsense>
    </div>
  </div>
</div>

