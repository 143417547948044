export const HOME_I18N_ENTRIES = {
  home_Data1: {
    es: '<p>Generación</p>',
    en: '<p>Generation</p>',
    pt: '<p>Criação</p>',
    fr: '<p>Generación</p>',
    eus: '<p>Generación</p>',
    cat: '<p>Generación</p>',
  },

  home_Data2: {
    es: '<p>Sube tu proyecto de carretera</p><p>y analiza todas sus señales</p>',
    en: '<p>Upload your road project and</p><p>analyze all of the signs</p>',
    pt: '<p>Carregue o seu projeto de estrada</p><p>e analise todos os seus sinais</p>',
    fr: '<p>Sube tu proyecto de carretera</p><p>y analiza todas sus señales</p>',
    eus: '<p>Sube tu proyecto de carretera</p><p>y analiza todas sus señales</p>',
    cat: '<p>Sube tu proyecto de carretera</p><p>y analiza todas sus señales</p>',
  },

  home_Data3: {
    es: '<p>Galería de proyectos</p>',
    en: '<p>Project Gallery</p>',
    pt: '<p>Galeria de projetos</p>',
    fr: '<p>Galería de proyectos</p>',
    eus: '<p>Galería de proyectos</p>',
    cat: '<p>Galería de proyectos</p>',
  },

  home_Data4: {
    es: '<p>Tus proyectos, carreteras,</p><p>tu galeria eyesNroad</p>',
    en: '<p>Your projects, roads,</p><p>eyesNroad gallery, etc.</p>',
    pt: '<p>Os seus projetos, as suas estradas,</p><p>a sua galeria eyesNroad…</p>',
    fr: '<p>Tus proyectos, carreteras,</p><p>tu galeria eyesNroad</p>',
    eus: '<p>Tus proyectos, carreteras,</p><p>tu galeria eyesNroad</p>',
    cat: '<p>Tus proyectos, carreteras,</p><p>tu galeria eyesNroad</p>',
  },

  home_Data5: {
    es: '<p>Proyectos en proceso</p>',
    en: '<p>Projects in progress</p>',
    pt: '<p>Projetos em processamento</p>',
    fr: '<p>Proyectos en proceso</p>',
    eus: '<p>Proyectos en proceso</p>',
    cat: '<p>Proyectos en proceso</p>',
  },

  home_Data6: {
    es: '<p>Gestión de proyectos</p>',
    en: '<p>Manage your projects</p>',
    pt: '<p>Projetos em processamento</p>',
    fr: '<p>Gestión de proyectos</p>',
    eus: '<p>Gestión de proyectos</p>',
    cat: '<p>Gestión de proyectos</p>',
  },

  home_Data7: {
    es: '<p>Galería de proyectos</p>',
    en: '<p>Project Gallery</p>',
    pt: '<p>Galeria de projetos</p>',
    fr: '<p>Galería de proyectos</p>',
    eus: '<p>Galería de proyectos</p>',
    cat: '<p>Galería de proyectos</p>',
  },

  home_Data8: {
    es: '<p>Mis proyectos</p>',
    en: '<p>My projects</p>',
    pt: '<p>Os meus projetos</p>',
    fr: '<p>Mis proyectos</p>',
    eus: '<p>Mis proyectos</p>',
    cat: '<p>Mis proyectos</p>',
  },

  home_Data9: {
    es: '<p>Mostrar todo</p>',
    en: '<p>Show all</p>',
    pt: '<p>Mostrar tudo</p>',
    fr: '<p>Mostrar todo</p>',
    eus: '<p>Mostrar todo</p>',
    cat: '<p>Mostrar todo</p>',
  },

  home_Data10: {
    es: '<p>Actualmente no tienes proyectos generados</p>',
    en: '<p>You currently have no completed projects</p>',
    pt: '<p>Atualmente, não tem projetos criados</p>',
    fr: '<p>Actualmente no tienes proyectos generados</p>',
    eus: '<p>Actualmente no tienes proyectos generados</p>',
    cat: '<p>Actualmente no tienes proyectos generados</p>',
  },

  home_Data11: {
    es: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
    en: '<p>Generate a new road project and analyze all of the signs.</p>',
    pt: '<p>Crie um novo projeto de estrada e analise todos os seus sinais.</p>',
    fr: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
    eus: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
    cat: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
  },

};
