import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ProjectThumbnail} from 'src/app/models/project-thumbnail';

/** Componente del slider la galería */
@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})
export class GallerySliderComponent {

  /** Comprobar si se hace click */
  isClickItem = false;

  /** Porcentaje del ancho */
  @Input() widthPercent = 30;
  /** Altura */
  @Input() height = 278;
  /** Márgen derecho */
  @Input() marginRight = 30;
  /** Obtiene thumbnails de las imágenes */
  @Input() images: ProjectThumbnail[] = [];

  /** Emite evento al filtar */
  @Output() filterChange = new EventEmitter<string>();

  /**
   * Constructor
   * @ignore
   */
  constructor(private router: Router) { }

  /**
   * Te lleva a la página del proyecto
   * @param projectId
   */
  onSliderClick(projectId: number) {
    if (this.isClickItem) {
      this.router.navigateByUrl(`projects/${projectId}`);
    }
  }

  /**
   * Revisa si haces click en el slider
   * @param isClick
   */
  clickItem(isClick: boolean) {
    this.isClickItem = isClick;
  }
}
