import { Component, Input, OnInit } from '@angular/core';
import { Language } from '../../../models/language';
import { I18nService } from '../../../core/services/i18n.service';
import { Router } from '@angular/router';

/**
 * Componente para seleccionar el idioma
 */
@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  /**
   * Idioma actual.
   */
  currentLanguage: Language;
  /**
   * Obtiene el nombre del idioma.
   */
  @Input() footer: boolean;

  /**
   * Constructor de la clase
   * @ignore
   */
  constructor(private i18nService: I18nService, private router: Router) {}

  /**
   * Metodo que se ejecuta al iniciar el componente
   * Carga el idioma actual
   */
  ngOnInit() {
    this.currentLanguage = this.i18nService.getCurrentLanguage();
  }

  /**
   * Lista de los idiomas disponibles.
   * @returns
   */
  public getOthersThanCurrentLanguage(): Language[] {
    return I18nService.getSupportedLanguages().filter(
      (x) => x.code !== this.i18nService.getCurrentLanguage().code
    );
  }

  /**
   * Cambia el idioma de la aplicación.
   * @param code
   */
  setCurrentLanguage(code: string) {
    this.i18nService.setCurrentLanguage(code);
    this.currentLanguage = this.i18nService.getCurrentLanguage();
    // this.reloadCurrentRoute();
  }

  /**
   * Cambia la ruta de la aplicación.
   */
  private reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url;

    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.router.url]);
    });
  }
}
